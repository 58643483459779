@font-face {
  font-family: "Share Tech Mono";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFcLowEFA87Heg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: "Share Tech Mono", Courier, monospace;
}

.fab__container {
  display: flex;
  width: 100%;
  height: 98vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fab__container > h1,
p {
  margin: 0;
}

html {
  background: #123;
}

body,
head {
  display: block;
  font-size: 42px;
  color: white;
}

head::before,
head::after,
body::before,
body::after {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  content: ".";
  color: rgba(255, 255, 255, 0);
  mix-blend-mode: screen;
  animation: 44s -27s move infinite ease-in-out alternate;
  z-index: -100;
}

body::before {
  text-shadow: 0.6342807532em -0.3159165643em 7px rgba(255, 255, 255, 0.9),
    0.8778956036em 2.2225296734em 7px rgba(255, 255, 255, 0.9),
    1.882308851em 0.0464273462em 7px rgba(255, 255, 255, 0.9),
    1.4186740386em 0.2348131182em 7px rgba(255, 255, 255, 0.9),
    1.6729169419em 1.2054985312em 7px rgba(255, 255, 255, 0.9),
    -0.4853326901em 1.1333175444em 7px rgba(255, 255, 255, 0.9),
    -0.4356653059em 1.1604611217em 7px rgba(255, 255, 255, 0.9),
    0.8527878357em 0.6868353293em 7px rgba(255, 255, 255, 0.9),
    1.1215168006em 0.1255329096em 7px rgba(255, 255, 255, 0.9),
    1.7753676273em 0.6118995288em 7px rgba(255, 255, 255, 0.9),
    0.1092435512em -0.3823754261em 7px rgba(255, 255, 255, 0.9),
    0.2671921411em 2.1892054153em 7px rgba(255, 255, 255, 0.9),
    0.2523947955em 0.7465482005em 7px rgba(255, 255, 255, 0.9),
    1.1104323365em 0.5849778755em 7px rgba(255, 255, 255, 0.9),
    1.81190329em -0.0966975623em 7px rgba(255, 255, 255, 0.9),
    1.032474467em 2.1299062785em 7px rgba(255, 255, 255, 0.9),
    -0.1798766228em -0.4515699626em 7px rgba(255, 255, 255, 0.9),
    1.5753238685em -0.0020042477em 7px rgba(255, 255, 255, 0.9),
    0.5619029572em 2.2525498149em 7px rgba(255, 255, 255, 0.9),
    1.3285907734em 2.0851421336em 7px rgba(255, 255, 255, 0.9),
    -0.0416306632em 0.3386752897em 7px rgba(255, 255, 255, 0.9),
    0.9435681978em -0.4368216444em 7px rgba(255, 255, 255, 0.9),
    0.9032254952em 0.5700388566em 7px rgba(255, 255, 255, 0.9),
    1.11265101em 0.7868116733em 7px rgba(255, 255, 255, 0.9),
    0.7461079583em 0.225757912em 7px rgba(255, 255, 255, 0.9),
    0.8518636838em -0.0997014804em 7px rgba(255, 255, 255, 0.9),
    1.4794479006em 0.4214718607em 7px rgba(255, 255, 255, 0.9),
    1.5919934258em 0.7574123406em 7px rgba(255, 255, 255, 0.9),
    0.8205258746em 2.0142524016em 7px rgba(255, 255, 255, 0.9),
    0.4140278415em -0.1189132177em 7px rgba(255, 255, 255, 0.9),
    2.3969209899em 1.0992566122em 7px rgba(255, 255, 255, 0.9),
    2.2960383618em 2.4619539727em 7px rgba(255, 255, 255, 0.9),
    1.2517358665em 1.6573099259em 7px rgba(255, 255, 255, 0.9),
    1.3659718486em -0.1273852391em 7px rgba(255, 255, 255, 0.9),
    1.4469871451em 1.8322284782em 7px rgba(255, 255, 255, 0.9),
    1.179072579em 1.4857875139em 7px rgba(255, 255, 255, 0.9),
    1.6836782785em 0.7476959604em 7px rgba(255, 255, 255, 0.9),
    2.391839742em -0.2568733078em 7px rgba(255, 255, 255, 0.9),
    1.3373413205em 2.1233715308em 7px rgba(255, 255, 255, 0.9),
    0.3160526219em -0.1149000589em 7px rgba(255, 255, 255, 0.9),
    0.7365434407em 1.7051279117em 7px rgba(255, 255, 255, 0.9);
  animation-duration: 44s;
  animation-delay: -27s;
}

body::after {
  text-shadow: 1.5731160288em 2.1568488639em 7px rgba(255, 255, 255, 0.9),
    -0.1755319995em -0.2125205864em 7px rgba(255, 255, 255, 0.9),
    0.5848118965em 2.0805315426em 7px rgba(255, 255, 255, 0.9),
    -0.1362657371em 0.3528026843em 7px rgba(255, 255, 255, 0.9),
    -0.2290202796em -0.0133724193em 7px rgba(255, 255, 255, 0.9),
    0.247387623em 1.881394492em 7px rgba(255, 255, 255, 0.9),
    1.2319271436em 2.3744427371em 7px rgba(255, 255, 255, 0.9),
    0.6397482327em 0.0009332278em 7px rgba(255, 255, 255, 0.9),
    1.1460226198em 0.5410259878em 7px rgba(255, 255, 255, 0.9),
    1.4114884307em 2.4394888926em 7px rgba(255, 255, 255, 0.9),
    2.2650707723em 1.3290906436em 7px rgba(255, 255, 255, 0.9),
    1.9807718529em -0.1021821304em 7px rgba(255, 255, 255, 0.9),
    0.6605875815em 0.5546722772em 7px rgba(255, 255, 255, 0.9),
    0.8269829309em 0.6560910373em 7px rgba(255, 255, 255, 0.9),
    1.0547933884em 1.7648697321em 7px rgba(255, 255, 255, 0.9),
    2.0190620939em 1.6409994332em 7px rgba(255, 255, 255, 0.9),
    1.9053788267em -0.032838178em 7px rgba(255, 255, 255, 0.9),
    1.0415690609em 0.9922544458em 7px rgba(255, 255, 255, 0.9),
    -0.3505999373em 1.7809345976em 7px rgba(255, 255, 255, 0.9),
    0.666642499em 0.2530392543em 7px rgba(255, 255, 255, 0.9),
    0.8567446757em 0.5980477466em 7px rgba(255, 255, 255, 0.9),
    1.8472224881em 2.1451732457em 7px rgba(255, 255, 255, 0.9),
    1.0730074872em 1.1249533248em 7px rgba(255, 255, 255, 0.9),
    -0.1159880386em 1.6925268615em 7px rgba(255, 255, 255, 0.9),
    -0.4606773833em 1.5022107596em 7px rgba(255, 255, 255, 0.9),
    2.4205553428em -0.3832384342em 7px rgba(255, 255, 255, 0.9),
    2.3977514076em 0.1683149428em 7px rgba(255, 255, 255, 0.9),
    -0.2382442879em 0.4213997534em 7px rgba(255, 255, 255, 0.9),
    1.4358400571em 0.6407742706em 7px rgba(255, 255, 255, 0.9),
    -0.1243263146em 1.4623166391em 7px rgba(255, 255, 255, 0.9),
    2.407005522em 1.2223396183em 7px rgba(255, 255, 255, 0.9),
    2.224302104em 0.4439952549em 7px rgba(255, 255, 255, 0.9),
    -0.237047994em 2.4352202536em 7px rgba(255, 255, 255, 0.9),
    1.3006298653em 1.098700092em 7px rgba(255, 255, 255, 0.9),
    -0.3369903719em -0.2609217864em 7px rgba(255, 255, 255, 0.9),
    2.2880513929em -0.1708991381em 7px rgba(255, 255, 255, 0.9),
    1.5794082629em 2.2684420769em 7px rgba(255, 255, 255, 0.9),
    0.4686746764em 1.0424103195em 7px rgba(255, 255, 255, 0.9),
    1.6992022873em -0.0647569005em 7px rgba(255, 255, 255, 0.9),
    1.2131852207em 1.9296854726em 7px rgba(255, 255, 255, 0.9),
    1.1185151405em 0.1499928118em 7px rgba(255, 255, 255, 0.9);
  animation-duration: 43s;
  animation-delay: -32s;
}

head::before {
  text-shadow: -0.4724794537em 2.2004525044em 7px rgba(255, 255, 255, 0.9),
    1.8548127325em 2.0545757803em 7px rgba(255, 255, 255, 0.9),
    -0.3172081928em -0.1390296639em 7px rgba(255, 255, 255, 0.9),
    0.9007196194em 1.7120293267em 7px rgba(255, 255, 255, 0.9),
    1.8079434284em 2.0222406308em 7px rgba(255, 255, 255, 0.9),
    0.9776810051em 1.628010971em 7px rgba(255, 255, 255, 0.9),
    -0.4500437053em 1.0397899817em 7px rgba(255, 255, 255, 0.9),
    0.8630305261em 1.1565636153em 7px rgba(255, 255, 255, 0.9),
    -0.110954643em 1.4935895988em 7px rgba(255, 255, 255, 0.9),
    -0.3228198679em 0.8506482871em 7px rgba(255, 255, 255, 0.9),
    0.0929443616em 2.1958421687em 7px rgba(255, 255, 255, 0.9),
    1.5375849338em 1.1954336534em 7px rgba(255, 255, 255, 0.9),
    0.1609722481em 2.4571161942em 7px rgba(255, 255, 255, 0.9),
    2.2709066156em 1.0007156301em 7px rgba(255, 255, 255, 0.9),
    2.2886783331em 2.467357286em 7px rgba(255, 255, 255, 0.9),
    1.3139165839em 1.4080345284em 7px rgba(255, 255, 255, 0.9),
    2.0347663967em -0.2976107934em 7px rgba(255, 255, 255, 0.9),
    1.0108557482em 1.6984448884em 7px rgba(255, 255, 255, 0.9),
    0.2307349218em 1.5617536245em 7px rgba(255, 255, 255, 0.9),
    0.6228234005em 0.7879213254em 7px rgba(255, 255, 255, 0.9),
    -0.38866817em 1.5634114172em 7px rgba(255, 255, 255, 0.9),
    0.7712308282em -0.2596695394em 7px rgba(255, 255, 255, 0.9),
    1.3172509219em 1.5397757514em 7px rgba(255, 255, 255, 0.9),
    0.8824342893em -0.4203613328em 7px rgba(255, 255, 255, 0.9),
    1.74032278em 0.8275160784em 7px rgba(255, 255, 255, 0.9),
    0.7204834675em 1.076477948em 7px rgba(255, 255, 255, 0.9),
    0.0074676772em 0.2264011254em 7px rgba(255, 255, 255, 0.9),
    0.2677922348em 1.8765796217em 7px rgba(255, 255, 255, 0.9),
    1.3829170221em 0.9981378934em 7px rgba(255, 255, 255, 0.9),
    0.6390864203em -0.1645206971em 7px rgba(255, 255, 255, 0.9),
    0.1679073698em 0.8433179339em 7px rgba(255, 255, 255, 0.9),
    1.730739607em 0.4124031833em 7px rgba(255, 255, 255, 0.9),
    0.7132137436em 1.1461186123em 7px rgba(255, 255, 255, 0.9),
    1.2978893479em 2.2576590544em 7px rgba(255, 255, 255, 0.9),
    -0.4903424535em 1.4528599582em 7px rgba(255, 255, 255, 0.9),
    0.0624973425em 0.7291720264em 7px rgba(255, 255, 255, 0.9),
    1.4308280225em 1.0267886932em 7px rgba(255, 255, 255, 0.9),
    0.3968128549em 0.4418647322em 7px rgba(255, 255, 255, 0.9),
    1.8797702347em -0.3250159933em 7px rgba(255, 255, 255, 0.9),
    1.6589246256em 1.1642739634em 7px rgba(255, 255, 255, 0.9),
    0.5483359992em -0.0550092737em 7px rgba(255, 255, 255, 0.9);
  animation-duration: 42s;
  animation-delay: -23s;
}

head::after {
  text-shadow: 2.4827189299em 0.5449535498em 7px rgba(255, 255, 255, 0.9),
    0.1557959042em 0.5213502566em 7px rgba(255, 255, 255, 0.9),
    -0.2766213824em 0.2214107035em 7px rgba(255, 255, 255, 0.9),
    2.3917470928em 0.6337117082em 7px rgba(255, 255, 255, 0.9),
    2.043259678em 0.1236529964em 7px rgba(255, 255, 255, 0.9),
    -0.1350181426em -0.1631799605em 7px rgba(255, 255, 255, 0.9),
    1.6969530488em 0.867854202em 7px rgba(255, 255, 255, 0.9),
    1.6709917533em 0.4127789167em 7px rgba(255, 255, 255, 0.9),
    2.224261081em 0.3457992478em 7px rgba(255, 255, 255, 0.9),
    2.4416145951em 0.9581659988em 7px rgba(255, 255, 255, 0.9),
    2.0317363781em 0.4840042033em 7px rgba(255, 255, 255, 0.9),
    0.468180538em 2.3334064373em 7px rgba(255, 255, 255, 0.9),
    -0.2742045603em 1.381457193em 7px rgba(255, 255, 255, 0.9),
    -0.2703674727em 1.6040767526em 7px rgba(255, 255, 255, 0.9),
    -0.1651524913em 0.7715993974em 7px rgba(255, 255, 255, 0.9),
    0.4836544649em -0.4337818736em 7px rgba(255, 255, 255, 0.9),
    1.2434238275em 0.8535653984em 7px rgba(255, 255, 255, 0.9),
    -0.3727966947em 2.4078978417em 7px rgba(255, 255, 255, 0.9),
    1.66013458em 1.9548135641em 7px rgba(255, 255, 255, 0.9),
    2.0610379507em 1.9557676837em 7px rgba(255, 255, 255, 0.9),
    1.1390434265em 2.0572540419em 7px rgba(255, 255, 255, 0.9),
    -0.411410316em 0.786026958em 7px rgba(255, 255, 255, 0.9),
    0.4846257721em 0.3522436852em 7px rgba(255, 255, 255, 0.9),
    0.6838481305em 2.3873275327em 7px rgba(255, 255, 255, 0.9),
    -0.3588128309em 0.4907476864em 7px rgba(255, 255, 255, 0.9),
    0.7177557392em 1.15451813em 7px rgba(255, 255, 255, 0.9),
    -0.06882239em 1.8195635202em 7px rgba(255, 255, 255, 0.9),
    2.447174571em -0.1538989293em 7px rgba(255, 255, 255, 0.9),
    1.1214122124em 1.2962316155em 7px rgba(255, 255, 255, 0.9),
    0.6833882685em -0.1389285645em 7px rgba(255, 255, 255, 0.9),
    -0.1917113604em 1.8148076675em 7px rgba(255, 255, 255, 0.9),
    2.0841259736em 0.5154079395em 7px rgba(255, 255, 255, 0.9),
    1.7353565074em 1.9661747246em 7px rgba(255, 255, 255, 0.9),
    -0.180551719em 1.3823200396em 7px rgba(255, 255, 255, 0.9),
    -0.3387980822em 0.6646752252em 7px rgba(255, 255, 255, 0.9),
    -0.1761110258em 1.3366386129em 7px rgba(255, 255, 255, 0.9),
    1.9496732689em 1.8875474197em 7px rgba(255, 255, 255, 0.9),
    2.1296155153em 0.088699129em 7px rgba(255, 255, 255, 0.9),
    2.2587294997em 1.4182215983em 7px rgba(255, 255, 255, 0.9),
    0.7575750908em 1.1595263435em 7px rgba(255, 255, 255, 0.9),
    0.8088683905em 0.8996026623em 7px rgba(255, 255, 255, 0.9);
  animation-duration: 41s;
  animation-delay: -19s;
}

@keyframes move {
  from {
    transform: rotate(0deg) scale(12) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(20px);
  }
}
